import React from "react";
import { FaGithub } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const LinkCard = ({ icon, link }) => {
  return (
    <a href={link} target="_blank" className="pr-6 text-3xl text-gray-300">
      {icon}
    </a>
  );
};

const ContactCard = () => {
  return (
    <div className="mt-8 flex items-center">
      <LinkCard icon={<FaGithub />} link="https://github.com/ShiwanthaH" />
      <LinkCard icon={<FaLinkedin />} link="https://www.linkedin.com/in/shiwanthah" />
      <LinkCard
        icon={<FaFacebookSquare />}
        link="https://www.facebook.com/himesh.adhikari.5"
      />
      <LinkCard
        icon={<FaInstagram />}
        link="https://www.instagram.com/shiwantha_h/"
      />
      <LinkCard icon={<FaXTwitter />} link="https://twitter.com/himeshadhi" />
    </div>
  );
};

export default ContactCard;
