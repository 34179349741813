import React from "react";

const MenuTile = ({ title, active }) => {
  const handleMouseEnter = (e) => {
    let obj = e.target;

    if (obj.id === "menu-tile") {
      obj = obj.children[0];
    }

    if (obj.id === "menu-title") {
      obj = obj.previousElementSibling;
    }

    obj.classList.remove("w-8");
    obj.classList.add("w-14");
    obj.classList.remove("h-[1px]");
    obj.classList.add("h-[2px]");
  };

  const handleMouseOut = (e) => {
    let obj = e.target;

    if (obj.id === "menu-tile") {
      obj = obj.children[0];
    }

    if (obj.id === "menu-title") {
      obj = obj.previousElementSibling;
    }

    obj.classList.remove("w-14");
    obj.classList.add("w-8");
    obj.classList.remove("h-[2px]");
    obj.classList.add("h-[1px]");
  };

  return (
    <div
      id="menu-tile"
      className="mb-4 flex items-center cursor-pointer"
      onMouseEnter={!active ? handleMouseEnter : () => {}}
      onMouseLeave={!active ? handleMouseOut : () => {}}
    >
      <div
        id="menu-bar"
        className={
          (active ? "w-14 h-[2px] bg-gray-50" : "w-8 h-[1px] bg-gray-500") +
          " transition-all duration-200 ease-in-out"
        }
      ></div>
      <div
        id="menu-title"
        className={
          "ml-4 uppercase font-[800] text-xs tracking-wider" +
          (active ? " text-gray-50" : " text-gray-500")
        }
      >
        {title}
      </div>
    </div>
  );
};

const NavMenu = () => {
  return (
    <div className="my-4 py-4">
      <MenuTile title="About" active={true} />
      <MenuTile title="Experience" active={false} />
      <MenuTile title="Projects" active={false} />
    </div>
  );
};

export default NavMenu;
