import React from "react";
import PdfViewer from "../Components/PDFViewer";

const Resume = () => {
  const pdfFileUrl = "/docs/Himesh Shiwantha_SE.pdf";

  return (
    <div>
      <PdfViewer fileUrl={pdfFileUrl} />
    </div>
  );
};

export default Resume;
