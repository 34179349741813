import React from "react";

const BioCard = () => {
  return (
    <div className="text-[16px] text-gray-500">
      <p className="py-4">
        Back in <b className="text-gray-300">2018</b>, as a school student, I have decide to take-part in
        a Coding Contest. It turned out to be a great experience and I have
        ended up being the 1st Runner-up. Since then, I have never looked back
        and followd my passion in IT.{" "}
      </p>
      <p className="py-4">
        Today I am a 3rd year undergraduate at{" "}
        <b className="text-gray-300">the leading technological university in Sri Lanka</b>. I have been
        working on various projects and have gained experience in various
        technologies. I have also{" "}
        <b className="text-gray-300">participated in many hackathons and coding contests</b>. I have{" "}
        <b className="text-gray-100">won several of them</b> and have been able to showcase my skills.{" "}
      </p>
      <p className="py-4">
        I am a <b className="text-gray-300">quick learner</b> and I am always{" "}
        <b className="text-gray-300">eager to learn new things</b>. I am passionate about what I do and I
        always <b className="text-gray-300">give my best to complete the task</b> at hand. I am a{" "}
        <b className="text-gray-300">team player</b> and I always collaborate with my team members to
        achieve the common goal. I am a <b className="text-gray-300">hard worker</b> and I always put my
        best effort to complete the task on time.{" "}
      </p>
    </div>
  );
};

export default BioCard;
