import React from "react";

const NameCard = () => {
  return (
    <div>
      <h1 className="text-[42px] font-bold text-left text-gray-50">
        Himesh Shiwantha
      </h1>
      <h4 className="text-[20px] font-[600] text-left text-gray-50">
        IT Undergraduate
      </h4>
      <p className="text-left text-[16px] w-3/4 px-0 py-4 text-gray-500">
        I build pixel-perfect, user-friendly and unique digital solutions.
      </p>
    </div>
  );
};

export default NameCard;
