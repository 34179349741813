import React from "react";
import BioCard from "../Components/BioCard";
import ContactCard from "../Components/ContactCard";
import NameCard from "../Components/NameCard";
import NavMenu from "../Components/NavMenu";
import CustomCursor from "../Components/CustomeCursor";

const Home = () => {
  return (
    <div className="w-full h-auto md:h-[100vh] scrollbar-hide md:overflow-hidden bg-slate-900 -z-20">
      <CustomCursor />
      <div className="w-3/4 h-full flex items-center m-auto relative z-10 flex-col md:flex-row">
        <div className="w-full md:w-1/2 h-3/4 flex flex-col justify-between px-4 pt-16 md:pt-0">
          <div>
            <NameCard />
            <NavMenu />
          </div>
          <ContactCard />
        </div>

        <div className="w-full md:w-1/2 h-3/4 px-4 text-justify pt-8 md:pt-0">
          <BioCard />
          <div className="text-[16px] mt-8"></div>
        </div>
      </div>
    </div>
  );
};

export default Home;
