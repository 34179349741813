import React from "react";

const NoPage = () => {
  return (
    <div>
      <h1 className="text-white">404</h1>
    </div>
  );
};

export default NoPage;
